import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function NotaryRemote() { 
	return( 
	<>  
    
   <Header />  
    <main> 
    <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section class="tp-service-details-area pt-120 pb-120">
            <div class="container">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="tp-service-widget">
                        <div class="tp-service-widget-item mb-40">
                           <div class="tp-service-widget-tab">
                              <h3 class="tp-service-widget-title">Our service</h3>
                              <ul>
                                 <li><a href="/Services">Estate Planning</a></li>
                                 <li><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                 <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                 <li><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                 <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                 <li><a href="/DebtManagement">Debt Management</a></li> 
                                 <li><a href="/WealthManagement">Wealth Management</a></li> 
                                 <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                 <li><a href="/NotaryPerson">Notary in person</a></li> 
                                 <li class="active"><a href="/NotaryRemote">Notary remote online</a></li> 
                                 <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                 {/* <li><a href="/Mortgageloan">Mortgage loan Officer</a></li>  */}
                              </ul>
                           </div>
                        </div> 
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Notary remote online (RON)</h3>
                        <p>Licenced RON available 24x7  . Call us to schedule online notary service</p>
                        <div class="tp-service-details-thumb">
                           <img src="../assets/images/s1.jpeg" alt=""/> 
                        </div>  
                        <div class="tp-service-bg tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              <h6>Please contact us for estimation</h6>
                              <a class="tp-btn-service" href="/ContactUs">Contact Us</a>
                           </div> 
                        </div>
 
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
    </main>
    <Footer/>

  </> 
  ); 
  } 
  export default NotaryRemote;