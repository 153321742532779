
function GreatValues() { 
	return( 
	<>
    <section class="tp-project-area pb-95 pt-95">
            <div class="container">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="tp-section-title-sub tp-project-title-wrapper text-center mb-30"> 
                        <h6>Before initiating a 401(k) rollover please reach out to us we will show you multiple options which will bring you great value </h6>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <div class="tp-project-tab-wrapper">
                        <ul class="nav nav-tab-ul nav-pills mb-60 justify-content-center wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s" id="pills-tab" role="tablist">
                           <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Notary in person (IPEN)</button>
                           </li>
                           <li class="nav-item" role="presentation">
                              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-Architecher" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Notary remote online (RON)</button>
                           </li>
                           <li class="nav-item" role="presentation">
                              <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-Large" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Notary for loan processing (NSA)</button>
                           </li>
                           <li class="nav-item" role="presentation">
                              <button class="nav-link" id="pills-Interiour-tab" data-bs-toggle="pill" data-bs-target="#pills-Interiour" type="button" role="tab" aria-controls="pills-Interiour" aria-selected="false">Mortgage loan Officer (MLO)</button>
                           </li>
                        </ul>

                        <div class="tab-content" id="pills-tabContent">
                           <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                              <div class="row">
                                 <div class="col-lg-6 col-md-6"> 
                                    <div class="tp-team-video-wrapper wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                       <img src="../assets/images/video.png" alt=""/>
                                       <div class="tp-play-btn">
                                          <a class="play-btn popup-video" href="#"><i class="fa-solid fa-play"></i></a>
                                       </div>
                                    </div> 
                                 </div> 
                                 <div class="col-lg-4 tp-about-center">
                                    <div class="tp-about-wrapper-2">
                                       <div class="tp-about-title-wrapper"> 
                                          <h3 class="tp-section-title">Before Initiating a 401(k) Rollover</h3>
                                       </div>
                                       <p>Licenced notary available 24x7 with one phone call away . We come to your doorstep or meet at common place  </p>
                                       <div class="tp-about-item-2">
                                          <div class="tp-about-item-2-inner d-flex">
                                             <div class="tp-about-item-2-icon">
                                                <span>
                                                <img src="../assets/images/play-circle.png" alt=""/>
                                                </span>
                                             </div>
                                             <div class="tp-about-item-2-content">
                                                <h5 class="tp-about-item-2-title">Licenced RON available 24x7</h5> 
                                             </div>
                                          </div>
                                          <div class="tp-about-item-2-inner d-flex">
                                             <div class="tp-about-item-2-icon">
                                                <span>
                                                <img src="../assets/images/play-circle.png" alt=""/>
                                                </span>
                                             </div>
                                             <div class="tp-about-item-2-content">
                                                <h5 class="tp-about-item-2-title">Notary available for loan signing </h5> 
                                             </div>
                                          </div>
                                          <div class="tp-about-item-2-inner d-flex">
                                             <div class="tp-about-item-2-icon">
                                                <span>
                                                <img src="../assets/images/play-circle.png" alt=""/>
                                                </span>
                                             </div>
                                             <div class="tp-about-item-2-content">
                                                <h5 class="tp-about-item-2-title">Licence in progress </h5> 
                                             </div>
                                          </div>
                                       </div> 
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-Architecher" role="tabpanel" aria-labelledby="pills-Architecher-tab">
                           <div class="row">
                                 <div class="col-lg-4 tp-about-center">
                                    <div class="tp-about-wrapper-2">
                                       <div class="tp-about-title-wrapper"> 
                                          <h3 class="tp-section-title">Before Initiating a 401(k) Rollover</h3>
                                       </div>
                                       <p>Licenced notary available 24x7 with one phone call away . We come to your doorstep or meet at common place  </p>
                                       <div class="tp-about-item-2">
                                          <div class="tp-about-item-2-inner d-flex">
                                             <div class="tp-about-item-2-icon">
                                                <span>
                                                <img src="../assets/images/play-circle.png" alt=""/>
                                                </span>
                                             </div>
                                             <div class="tp-about-item-2-content">
                                                <h5 class="tp-about-item-2-title">Licenced RON available 24x7</h5> 
                                             </div>
                                          </div>
                                          <div class="tp-about-item-2-inner d-flex">
                                             <div class="tp-about-item-2-icon">
                                                <span>
                                                <img src="../assets/images/play-circle.png" alt=""/>
                                                </span>
                                             </div>
                                             <div class="tp-about-item-2-content">
                                                <h5 class="tp-about-item-2-title">Notary available for loan signing </h5> 
                                             </div>
                                          </div>
                                          <div class="tp-about-item-2-inner d-flex">
                                             <div class="tp-about-item-2-icon">
                                                <span>
                                                <img src="../assets/images/play-circle.png" alt=""/>
                                                </span>
                                             </div>
                                             <div class="tp-about-item-2-content">
                                                <h5 class="tp-about-item-2-title">Licence in progress </h5> 
                                             </div>
                                          </div>
                                       </div> 
                                    </div>
                                 </div>
                                 
                                 <div class="col-lg-6 col-md-6"> 
                                    <div class="tp-team-video-wrapper wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                                       <img src="../assets/images/video.png" alt=""/>
                                       <div class="tp-play-btn">
                                          <a class="play-btn popup-video" href="#"><i class="fa-solid fa-play"></i></a>
                                       </div>
                                    </div> 
                                 </div> 
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-Large" role="tabpanel" aria-labelledby="pills-Large-tab">
                              <div class="row">
                                 <div class="col-lg-6 col-md-6">
                                  
                                 </div> 
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-Interiour" role="tabpanel" aria-labelledby="pills-Interiour-tab">
                              <div class="row">
                                 <div class="col-lg-6 col-md-6">
                                  
                                 </div> 
                              </div>
                           </div>
                           <div class="tab-pane fade" id="pills-House" role="tabpanel" aria-labelledby="pills-House-tab">
                              <div class="row">
                                 <div class="col-lg-6 col-md-6">
                                  
                                 </div> 
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
  </> 
  ); 
  } 
  export default GreatValues;