import React from 'react';
import Header from '../../components/Header';   
import Footer from '../../components/Footer'; 
import Banner from './Banner';  
import Specialize from './Specialize';
import WeServes from './WeServes';
import GreatValues from './GreatValues';  
import Testimonials from './Testimonials';
import RequestQuote from './RequestQuote';
import BannerSlider from './BannerSlider';
import TestimonialTest from './TestimonialTest'; 
function Home() { 
	return( 
	<>
  
    <Header />  
     <main>
    {/* <Banner/>  */}
    <BannerSlider/>
    {/* <Specialize/> */}
    <WeServes/> 
    {/* <GreatValues/> */}
    <Testimonials/>
    {/* <TestimonialTest/> */}
    {/* <RequestQuote/>  */}
    </main> 
    <Footer/>
        
  </> 
  ); 
  } 
  export default Home;