import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function ContactUs() { 
	return( 
	<>  
    
    <Header /> 
    <main> 
        <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="tp-contact-breadcrumb-area pt-120 pb-120">
            <div class="container">
               <div class="tp-contact-breadcrumb-box"> 
                  <div class="row">
                     <div class="col-lg-7">
                        <div class="tp-contact-breadcrumb-content">
                           <h3 class="tp-contact-breadcrumb-title">Get in touch</h3>
                           <p>We are here for you! how can we help, We are here for you! </p>
                           <form id="contact-form" action="#" method="POST">
                              <div class="row">
                                 <div class="col-md-12">
                                    <div class="tp-contact-input">
                                       <input name="name" type="text" placeholder="Your Name"/>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="tp-contact-input">
                                       <input name="phone" type="text" placeholder="Phone Here"/>
                                    </div>
                                 </div>
                                 <div class="col-md-6">
                                    <div class="tp-contact-input">
                                       <input name="email" type="email" placeholder="Email Here"/>
                                    </div>
                                 </div>
                                 <div class="col-md-12">
                                    <div class="tp-contact-input">
                                       <textarea name="message" placeholder="Message Here"></textarea>
                                    </div>
                                 </div>
                                 <div class="col-md-12">
                                    <div class="tp-contact-breadcrumb-btn">
                                       <button type="submit" class="tp-btn">SUBMIT</button>
                                       <p class="ajax-response"></p>
                                    </div>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                     <div class="col-lg-5">
                        <div class="tp-contact-breadcrumb-wrapper">

                           {/* <div class="tp-contact-breadcrumb-item mb-40 d-flex">
                              <div class="tp-contact-breadcrumb-item-icon">
                                 <span><i class="fa-solid fa-location-dot"></i></span>
                              </div>
                              <div class="tp-contact-breadcrumb-item-content">
                                 <h3 class="tp-contact-breadcrumb-item-title">Address</h3>
                                 <a href="#" target="_blank">18711 Birchwoods Groves Dr Lutz 33558</a>
                              </div>
                           </div> */}

                           <div class="tp-contact-breadcrumb-item mb-40 d-flex">
                              <div class="tp-contact-breadcrumb-item-icon">
                                 <span><i class="fa-solid fa-envelope"></i></span>
                              </div>
                              <div class="tp-contact-breadcrumb-item-content">
                                 <h3 class="tp-contact-breadcrumb-item-title">Mail Us</h3>
                                 <a href="#" target="_blank"><span class="__cf_email__" data-cfemail="#">getrich@kuberafinancialservices.com</span></a>
                              </div>
                           </div>

                           <div class="tp-contact-breadcrumb-item d-flex">
                              <div class="tp-contact-breadcrumb-item-icon">
                                 <span><i class="fa-solid fa-phone"></i></span>
                              </div>
                              <div class="tp-contact-breadcrumb-item-content">
                                 <h3 class="tp-contact-breadcrumb-item-title">Telephone</h3> 
                                 <a>813 809 6438</a>
                              </div>
                           </div>

                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         {/* <div class="tp-contact-map-area map-margin">
            <div class="tp-contact-map">
               <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.921215223308!2d-82.5005491!3d28.1488865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2bee6ee5b8bf9%3A0x536fb01ad446cc5b!2s18711%20Birchwood%20Groves%20Dr%2C%20Lutz%2C%20FL%2033558%2C%20USA!5e0!3m2!1sen!2sin!4v1711593652483!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
         </div> */}
    </main>
    <Footer/>

  </> 
  ); 
  } 
  export default ContactUs;