import React from 'react';

function  Footer () {
  return (
  <>
   
   <footer class="tp-footer-area-2 pt-100 p-relative z-index-1">
            {/* <div class="bg-patten"><img src="../assets/images/patten-bg.png" alt="" /></div>  */}
            <div class="tp-footer-main-area tp-footer-border">
               <div class="container">
                  <div class="row">
                     <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                        <div class="tp-footer-widget tp-footer-2-col-1 mb-50">
                           <div class="tp-footer-logo mb-20">
                              <h1>Kubera Financial Services LLC</h1>
                           </div>
                           <div class="tp-footer-widget-content">
                              <p>Kubera Financial Services LLC primary mission is to provide financial freedom for our clients .Our vision is to be a step ahead in the financial revolution.</p>
                              <p>We offer comprehensive financial services designed to help you achieve financial security and peace of mind.</p>
                              <div class="tp-footer-widget-social">
                                 <a target="_blank"href="https://www.facebook.com/people/Kubera-Financial-Services-LLC/61558366419478/"><i class="fab fa-facebook-f"></i></a>
                                 <a target="_blank" href="https://twitter.com/kfsllc"><i class="fab fa-twitter"></i></a>
                                 {/* <a href="#"><i class="fa-brands fa-instagram"></i></a> */}
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6">
                        <div class="tp-footer-widget tp-footer-2-col-2 mb-50">
                           <h3 class="tp-footer-widget-title">Services</h3>
                           <div class="tp-footer-widget-content-link">
                           <div class="tp-footer-widget-content">
                              <ul>
                                 <li><a href="/Services">Estate Planning</a></li>
                                 <li><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                 <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                 <li><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                 <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                 <li><a href="/DebtManagement">Debt Management</a></li> 
                              </ul>
                           </div>
                           <div class="tp-footer-widget-content">
                              <ul>
                                 <li><a href="/WealthManagement">Wealth Management</a></li> 
                                 <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                 <li><a href="/NotaryPerson">Notary in person</a></li> 
                                 <li><a href="/NotaryRemote">Notary remote online</a></li> 
                                 <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                 {/* <li><a href="/Mortgageloan">Mortgage loan Officer</a></li>  */}
                              </ul>
                           </div>
                        </div> 
                        </div> 
                     </div>
                     <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                        <div class="tp-footer-widget tp-footer-2-col-4 mb-50">
                           <h3 class="tp-footer-widget-title">Quick Links</h3>
                           <div class="tp-footer-widget-content tp-footer-widget-content-1">
                              <ul>
                                 <li><a href="/">Home</a></li>
                                 <li><a href="/AboutUs">About Us</a></li>
                                 <li><a href="/Payment">Payment </a></li>
                                 <li><a href="/ContactUs">Contact Us </a></li> 
                              </ul>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="tp-footer-copyright-area p-relative">
               <div class="container">
                  <div class="row"> 
                     {/* <div class="col-md-12 col-lg-6">
                        <div class="tp-footer-copyright-inner text-lg-left">
                           <a href="#">Privacy Policy</a>
                           <a href="#">Help Center</a> 
                        </div>
                     </div> */}
                     <div class="col-md-12 col-lg-12">
                        <div class="tp-footer-copyright-inner tp-footer-copyright-inner-1">
                           <p>© 2024 Kubera Financial Services All Rights Reserved.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>

  </>
);
}

export default Footer;