
function Testimonials() { 
	return( 
	<>
    
    <section class="tp-testimonial-area-3 we-specialize p-relative pt-100 pb-90" data-bg-color="#fff7ea">
            <div class="bg-patten"><img src="../assets/images/patten-bg.png" alt="" /></div>
            {/* <div class="tp-testimonial-shape-3"> 
               <img class="shape-2" src="assets/img/support/shape-4.png" alt="" />
            </div> */}
            <div class="container">
            <div class="row">
                  <div class="col-lg-12 col-md-12">
                     <div class="tp-service-title-wrapper text-center"> 
                        <h3 class="tp-section-title">Happy Families</h3>
                     </div>
                  </div>
                  {/*<div class="tp-service-area  col-lg-8 col-md-4"> 
                     <div class="tp-testimonial-2-active1 swiper-container wow fadeInUp" data-wow-duration="1s"  data-wow-delay=".3s">
                        <div class="swiper-wrapper">
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>Our team of experienced professionals is dedicated to understanding your financial goals and providing you with the personalized strategies necessary to reach them. </p>
                                 </div> 
                           </div>
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>We believe in building long-term relationships with our clients, providing the guidance and support you need to navigate the complex world of finance. Trust us to help you master your financial future.</p>
                                 </div> 
                           </div>
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>Don't just take our word for it hear what our customers have to say about us! we have helped thousand people</p>
                                 </div> 
                           </div> 
                        </div>
                     </div>
                  </div>
                   <div class="col-lg-2 col-md-4">
                     <div class="tp-service-nav tp-testimonial text-end">
                        <button type="button" class="service-button-prev-1 testimonial-button-next-1"><i class="fa-regular fa-arrow-left"></i>
                        </button>
                        <button type="button" class="service-button-next-1 testimonial-button-next-1"><i class="fa-regular fa-arrow-right"></i>
                        </button>
                     </div>
                  </div> */}
               </div>
               <div class="row"> 
                           <div class="col-md-6">
                              <div class="tp-testimonial-item-3"> 
                                 {/* <h6>Good Guidance and Support </h6> */}
                                 <p>Awareness session from Kubera Financial Services was an eye opening for me and family, I was able to protect myself and my family. Thank you Kubera Financial Services for helping my family. I endorse this company heartfully.</p>
                                 <div class="tp-testimonial-user-3 d-flex">
                                    <div class="tp-testimonial-user-3-thumb">
                                       <img src="../assets/images/user-1.jpg" alt="" />
                                    </div>
                                    <div class="tp-testimonial-user-3-content">
                                       <h4 class="tp-testimonial-user-3-title">Satish & Family </h4>
                                       <span>(Tampa)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="tp-testimonial-item-3"> 
                                 {/* <h6>Master of financial future</h6> */}
                                 <p>I connected with Kubera Financial Services for estate planning , their knowledge is excellent  and consultation was completely free.<br/> Thank you Kubera Financial Services for helping me.</p>
                                 <div class="tp-testimonial-user-3 d-flex">
                                    <div class="tp-testimonial-user-3-thumb">
                                       <img src="../assets/images/user-2.jpg" alt="" />
                                    </div>
                                    <div class="tp-testimonial-user-3-content">
                                       <h4 class="tp-testimonial-user-3-title">Dileep & Family</h4>
                                       <span> (Boston)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="tp-testimonial-item-3"> 
                                 {/* <h6>Master of financial future</h6> */}
                                 <p>I contacted Kubera Financial Services for debt management and their guidance is really really working for me. I am certain in 2 years i will be out of  my debt and focus on building wealth.</p>
                                 <div class="tp-testimonial-user-3 d-flex">
                                    <div class="tp-testimonial-user-3-thumb">
                                       <img src="../assets/images/user-3.jpg" alt="" />
                                    </div>
                                    <div class="tp-testimonial-user-3-content">
                                       <h4 class="tp-testimonial-user-3-title">Harsha & Family</h4>
                                       <span>(Texas)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div class="col-md-6">
                              <div class="tp-testimonial-item-3"> 
                                 {/* <h6>Master of financial future</h6> */}
                                 <p>Absolutely worth it. I feel much relaxed after having a consultation with Kubera Financial Services. I endorse this team.</p>
                                 <div class="tp-testimonial-user-3 d-flex">
                                    <div class="tp-testimonial-user-3-thumb">
                                       <img src="../assets/images/user.png" alt="" />
                                    </div>
                                    <div class="tp-testimonial-user-3-content">
                                       <h4 class="tp-testimonial-user-3-title">Naveen & Family</h4>
                                       <span>(Atlanta)</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                     </div>
                      
         </section>
  </> 
  ); 
  } 
  export default Testimonials;