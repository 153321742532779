
function  WeServes() { 
	return( 
	<>
    
    <section class="tp-service-area we-specialize pt-120 pb-90" data-bg-color="#fff">
            {/* <div class="bg-patten"><img src="../assets/images/patten-bg.png" alt="" /></div> */}
            <div class="container">
               <div class="row">
                  <div class="col-lg-4 col-md-8">
                     <div class="tp-service-title-wrapper"> 
                        <h3 class="tp-section-title">We Specialize,</h3>
                     </div>
                  </div>
                  <div class="tp-service-area  col-lg-8 col-md-4"> 
                     <div class="tp-testimonial-2-active1 swiper-container wow fadeInUp" data-wow-duration="1s"  data-wow-delay=".3s">
                        <div class="swiper-wrapper">
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>We believe in building long-term relationships with our clients, providing the guidance and support you need to navigate the complex world of finance. Trust us to help you master your financial future.</p>
                                 </div> 
                           </div>
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>Don't just take our word for it hear what our customers have to say about us! we have helped thousand people</p>
                                 </div> 
                           </div>
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>Don't just take our word for it hear what our customers have to say about us! we have helped thousand people</p>
                                 </div> 
                           </div> 
                        </div>
                     </div>
                  </div>
                  {/* <div class="col-lg-2 col-md-4">
                     <div class="tp-service-nav tp-testimonial text-end">
                        <button type="button" class="service-button-prev-1 testimonial-button-next-1"><i class="fa-regular fa-arrow-left"></i>
                        </button>
                        <button type="button" class="service-button-next-1 testimonial-button-next-1"><i class="fa-regular fa-arrow-right"></i>
                        </button>
                     </div>
                  </div> */}
               </div>
               <div class="row">  
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/Services">
                        <img src="../assets/images/1.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Estate Planning</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/RetirementPlanning">
                        <img src="../assets/images/2.png" alt="" />
                        <div class="tp-project-thumb-info"> 
                           <h4>Retirement Planning</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/TaxOptimization">
                        <img src="../assets/images/3.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Tax Optimization</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/LifeInsurance">
                        <img src="../assets/images/4.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Life Insurance & Annuities</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/ExpertRollovers">
                        <img src="../assets/images/5.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Expert 401K Rollovers</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/DebtManagement">
                        <img src="../assets/images/6.png" alt="" />
                        <div class="tp-project-thumb-info"> 
                           <h4>Debt Management</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/WealthManagement">
                        <img src="../assets/images/7.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Wealth Management</h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/BusinessOpportunity">
                        <img src="../assets/images/8.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Business Opportunity </h4> 
                        </div>
                        </a>
                     </div>
                  </div> 
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/NotaryPerson">
                        <img src="../assets/images/9.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Notary in person </h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/NotaryRemote">
                        <img src="../assets/images/10.png" alt="" />
                        <div class="tp-project-thumb-info"> 
                           <h4>Notary remote online </h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/NotaryLoan">
                        <img src="../assets/images/11.png" alt="" />
                        <div class="tp-project-thumb-info">
                           <h4>Notary for loan processing </h4> 
                        </div>
                        </a>
                     </div>
                  </div>
                  {/* <div class="col-lg-3 col-md-6">
                     <div class="tp-project-thumb">
                        <a href="/Mortgageloan">
                           <img src="../assets/images/12.png" alt="" />
                           <div class="tp-project-thumb-info">
                              <h4>Mortgage loan Officer </h4> 
                           </div>
                        </a>
                     </div>
                  </div> */}
               </div>
               {/* <div class="row">  
                  <div class="col-lg-12 col-md-12">
                     <div class="global-clients">
                        <h2>Global Clients Around The World</h2>
                        <h1>401(k) Rollover </h1>
                     </div>
                  </div>
               </div> */}
            </div>
         </section>
  </> 
  ); 
  } 
  export default WeServes;