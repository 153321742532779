
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home/Home";
import Banner from "./pages/Home/Banner"; 
import Specialize from "./pages/Home/Specialize";
import WeServes from "./pages/Home/WeServes";
import GreatValues from "./pages/Home/GreatValues";
import Testimonials from "./pages/Home/Testimonials";  
import TestimonialTest from "./pages/Home/TestimonialTest"; 
import RequestQuote from "./pages/Home/RequestQuote";


import AboutUs from "./pages/AboutUs/AboutUs";  
import Services from "./pages/Services/Services"; 
import ContactUs from "./pages/ContactUs/ContactUs";  
import RetirementPlanning from "./pages/Services/RetirementPlanning";
import TaxOptimization from "./pages/Services/TaxOptimization";
import LifeInsurance from "./pages/Services/LifeInsurance";
import ExpertRollovers from "./pages/Services/ExpertRollovers";
import DebtManagement from "./pages/Services/DebtManagement";
import WealthManagement from "./pages/Services/WealthManagement";
import BusinessOpportunity from "./pages/Services/BusinessOpportunity";
import NotaryPerson from "./pages/Services/NotaryPerson";
import NotaryRemote from "./pages/Services/NotaryRemote";
import NotaryLoan from "./pages/Services/NotaryLoan";
import Mortgageloan from "./pages/Services/Mortgageloan";
import Payment from "./pages/Payment/Payment";
import BannerSlider from "./pages/Home/BannerSlider";
 

const routes = (
  <Router>    
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/Banner' element={<Banner/>} /> 
      <Route path='/Specialize' element={<Specialize/>} /> 
      <Route path='/WeServes' element={<WeServes/>} /> 
      <Route path='/GreatValues' element={<GreatValues/>} /> 
      <Route path='/Testimonials' element={<Testimonials/>} /> 
      <Route path='/RequestQuote' element={<RequestQuote/>} />  
      
      <Route path='/AboutUs' element={<AboutUs/>} />  
      <Route path='/Services' element={<Services/>} />
      <Route path='/ContactUs' element={<ContactUs/>} /> 

      <Route path='/RetirementPlanning' element={<RetirementPlanning/>} /> 
      <Route path='/TaxOptimization' element={<TaxOptimization/>} /> 
      <Route path='/LifeInsurance' element={<LifeInsurance/>} /> 
      <Route path='/ExpertRollovers' element={<ExpertRollovers/>} /> 
      <Route path='/DebtManagement' element={<DebtManagement/>} /> 
      <Route path='/WealthManagement' element={<WealthManagement/>} /> 
      <Route path='/BusinessOpportunity' element={<BusinessOpportunity/>} /> 
      <Route path='/NotaryPerson' element={<NotaryPerson/>} /> 
      <Route path='/NotaryRemote' element={<NotaryRemote/>} /> 
      <Route path='/NotaryLoan' element={<NotaryLoan/>} /> 
      <Route path='/Mortgageloan' element={<Mortgageloan/>} />  
      <Route path='/Payment' element={<Payment/>} />  
      <Route path='/BannerSlider' element={<BannerSlider/>} />  
      <Route path='/TestimonialTest' element={<TestimonialTest/>} />   
 
      
      
    </Routes>
 </Router> )
function App() {
  return (
    <>
        {routes}  
    </>
  );
}

export default App;
