// import 'bootstrap/dist/css/bootstrap.min.css';  
import {Carousel } from 'react-bootstrap';  
import img1 from '../../components/slider-1.jpg'; 
import img2 from '../../components/slider-2.jpg'; 
import img3 from '../../components/slider-3.jpg'; 
function BannerSlider() {  
  
  return (  
    <>  
    <div class="banner-slider">  
    <Carousel fade>  
      <Carousel.Item>  
      <img style={{maxHeight:"400vh"}}  
          className="banner-slider-image"  
          src={img1}  
          alt="First slide"  
        />  
        <Carousel.Caption>  
          <div class="col-lg-7">
              <div class="tp-hero-content p-relative">
                <div class="tp-hero-title-wrapper"> 
                    <h2 class="tp-hero-title">Protect yourself</h2>
                    <p>Protecting the head of the family financially is crucial for ensuring stability and security. Have you planned any of these Emergency Fund, Insurance Coverage, Financial Planning, Diversified Investment, Estate Planning, Education Career development, Legal Protection. Don’t worry if you have not done anything we are here to help to start protecting yourself</p>
                </div>
                <div class="tp-hero-button-wrapper d-flex flex-wrap align-items-center">
                    <div class="tp-hero-btn mr-30">
                      <a class="tp-btn" href="/ContactUs">Contact Us</a>
                    </div> 
                </div>
              </div>
          </div> 
        </Carousel.Caption>  
    </Carousel.Item>  

    <Carousel.Item>  
      <img  
        className="banner-slider-image"  
        src={img2}  
        alt="Second slide"  
      />   
      <Carousel.Caption>  
        <div class="col-lg-7">
            <div class="tp-hero-content p-relative">
              <div class="tp-hero-title-wrapper"> 
                  <h2 class="tp-hero-title">Protect your family</h2>
                  <p>Protecting your family financially involves various strategies to ensure stability, security, and resilience in the face of unexpected events or financial challenges. We are here to help guide you to start protecting your family</p>
              </div>
              <div class="tp-hero-button-wrapper d-flex flex-wrap align-items-center">
                  <div class="tp-hero-btn mr-30">
                    <a class="tp-btn" href="/ContactUs">Contact Us</a>
                  </div> 
              </div>
            </div>
        </div> 
      </Carousel.Caption>  
    </Carousel.Item>  
    <Carousel.Item>  
      <img  
        className="banner-slider-image"  
        src={img3}  
        alt="Third slide"  
      />  
      <Carousel.Caption>  
        <div class="col-lg-7">
          <div class="tp-hero-content p-relative">
            <div class="tp-hero-title-wrapper"> 
                <h2 class="tp-hero-title">Reduce Debt</h2>
                <p>Develop a plan to manage and reduce debt. Prioritize high-interest debt for repayment and explore strategies such as debt consolidation or refinancing to lower interest rates and streamline payments.</p>
                <p>Review your financial plan regularly and make adjustments as needed based on changes in your life circumstances, financial goals, market conditions, and tax laws. Stay proactive and flexible to adapt to evolving financial needs and opportunities</p>
            </div>
            <div class="tp-hero-button-wrapper d-flex flex-wrap align-items-center">
                <div class="tp-hero-btn mr-30">
                  <a class="tp-btn" href="/ContactUs">Contact Us</a>
                </div> 
            </div>
          </div>
        </div>  
      </Carousel.Caption>  
    </Carousel.Item>  
    <Carousel.Item>  
      <img style={{maxHeight:"400vh"}}  
          className="banner-slider-image"  
          src={img1}  
          alt="First slide"  
        />  
        <Carousel.Caption>  
        <div class="col-lg-7">
            <div class="tp-hero-content p-relative">
              <div class="tp-hero-title-wrapper"> 
                  <h2 class="tp-hero-title">Build Wealth</h2>
                  <p>Passive income is no longer just an option; it's a priority, especially given the current inflation circumstances. We can assist you in exploring new business opportunities with absolutely no initial capital, allowing you to start building wealth</p>
              </div>
              <div class="tp-hero-button-wrapper d-flex flex-wrap align-items-center">
                  <div class="tp-hero-btn mr-30">
                    <a class="tp-btn" href="/ContactUs">Contact Us</a>
                  </div>
              
              </div>
            </div> 
          </div> 
        </Carousel.Caption>  
    </Carousel.Item>  

    <Carousel.Item>  
      <img  
        className="banner-slider-image"  
        src={img2}  
        alt="Second slide"  
      />   
      <Carousel.Caption>  
        <div class="col-lg-7">
          <div class="tp-hero-content p-relative">
            <div class="tp-hero-title-wrapper"> 
                <h2 class="tp-hero-title">Retirement Planning</h2>
                <p>Estimate your retirement needs and develop a plan to accumulate sufficient savings for retirement. </p>
                <p>Contribute regularly to retirement accounts such as 401(k)s, IRAs, or pensions, and consider employer matching contributions. We are here to help guide you to start retirement planning</p>
            </div>
            <div class="tp-hero-button-wrapper d-flex flex-wrap align-items-center">
                <div class="tp-hero-btn mr-30">
                  <a class="tp-btn" href="/ContactUs">Contact Us</a>
                </div> 
            </div>
          </div>
        </div> 
      </Carousel.Caption>  
    </Carousel.Item>  
    <Carousel.Item>  
      <img  
        className="banner-slider-image"  
        src={img3}  
        alt="Third slide"  
      />  
      <Carousel.Caption>  
        <div class="col-lg-7">
          <div class="tp-hero-content p-relative">
            <div class="tp-hero-title-wrapper"> 
                <h2 class="tp-hero-title">Estate Planning</h2>
                <p>Create an estate plan to ensure that your assets are distributed according to your wishes and minimize taxes and legal fees. </p>
                <p>Establish a will or trust, designate beneficiaries for retirement accounts and life insurance policies, and consider strategies to transfer wealth efficiently. We are here to help guide you to start estate planning</p>
            </div>
            <div class="tp-hero-button-wrapper d-flex flex-wrap align-items-center">
                <div class="tp-hero-btn mr-30">
                  <a class="tp-btn" href="/ContactUs">Contact Us</a>
                </div> 
            </div>
          </div>
        </div> 
      </Carousel.Caption>  
    </Carousel.Item>  

  </Carousel>  
  </div>  
    </>  
  );  
}  
export default BannerSlider;  