import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function Mortgageloan() { 
	return( 
	<>  
    
   <Header />  
    <main> 
    <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section class="tp-service-details-area pt-120 pb-120">
            <div class="container">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="tp-service-widget">
                        <div class="tp-service-widget-item mb-40">
                           <div class="tp-service-widget-tab">
                              <h3 class="tp-service-widget-title">Our service</h3>
                              <ul>
                                 <li><a href="/Services">Estate Planning</a></li>
                                 <li><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                 <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                 <li><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                 <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                 <li><a href="/DebtManagement">Debt Management</a></li> 
                                 <li><a href="/WealthManagement">Wealth Management</a></li> 
                                 <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                 <li><a href="/NotaryPerson">Notary in person</a></li> 
                                 <li><a href="/NotaryRemote">Notary remote online</a></li> 
                                 <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                 {/* <li class="active"><a href="/Mortgageloan">Mortgage loan Officer</a></li>  */}
                              </ul>
                           </div>
                        </div> 
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Mortgage loan Officer (MLO)</h3>
                        <p>Licence in progress . Call us for markets best interest rate before you make any decision</p>
                        <div class="tp-service-details-thumb">
                           <img src="../assets/images/s1.jpeg" alt=""/> 
                        </div> 
                     
 
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
    </main>
    <Footer/>

  </> 
  ); 
  } 
  export default Mortgageloan;