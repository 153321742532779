import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function RetirementPlanning() { 
	return( 
	<>  
    
   <Header />  
    <main> 
    <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section class="tp-service-details-area pt-120 pb-120">
            <div class="container">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="tp-service-widget">
                        <div class="tp-service-widget-item mb-40">
                           <div class="tp-service-widget-tab">
                              <h3 class="tp-service-widget-title">Our service</h3>
                              <ul>
                                 <li><a href="/Services">Estate Planning</a></li>
                                 <li class="active"><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                 <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                 <li><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                 <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                 <li><a href="/DebtManagement">Debt Management</a></li> 
                                 <li><a href="/WealthManagement">Wealth Management</a></li> 
                                 <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                 <li><a href="/NotaryPerson">Notary in person</a></li> 
                                 <li><a href="/NotaryRemote">Notary remote online</a></li> 
                                 <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                 {/* <li><a href="/Mortgageloan">Mortgage loan Officer</a></li>  */}
                              </ul>
                           </div>
                        </div> 
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Retirement Planning</h3>
                        <p>Retirement planning refers to the process of setting financial goals and creating a strategy to achieve those goals for the period of time after an individual stops working. The goal is to ensure a financially secure and comfortable retirement. </p>
                        <p>Retirement planning involves a combination of saving, investing, and managing assets to accumulate the necessary funds to support oneself during retirement. </p>
                        <div class="tp-service-details-thumb">
                           <img src="../assets/images/s2.png" alt=""/> 
                        </div> 
                        <div class="tp-postbox-list-wrapper">
                              <div class="tp-postbox-list mr-40">
                                 <h2 class="tp-postbox-list-title-1">Key components of retirement planning include:</h2>
                                 <h4 class="tp-postbox-list-title"><i class="fa-regular fa-check"></i> Setting Goals</h4>
                                 <p>Determine your retirement goals, including the desired lifestyle, activities, and expenses during retirement. Consider factors such as healthcare, travel, and other potential costs.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Budgeting and Saving</h4>
                                    <p>Create a budget to understand your current expenses and identify areas where you can save. Regularly contribute to retirement savings vehicles such as employer-sponsored retirement plans (e.g., 401(k)), individual retirement accounts (IRAs), or other investment accounts.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i> Investment Strategy</h4>
                                 <p>Develop an investment strategy based on your risk tolerance, time horizon, and financial goals. Diversify your investments across different asset classes to manage risk and potentially increase returns.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Employer-sponsored Plans</h4>
                                 <p>Take advantage of employer-sponsored retirement plans if available, as they often provide tax advantages and may include employer contributions.</p>
                              </div> 
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Social Security</h4>
                                 <p>Understand how Social Security benefits work and consider the optimal time to start receiving them based on your individual circumstances.</p>
                              </div> 
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Insurance</h4>
                                 <p>Ensure you have adequate health insurance coverage, as healthcare costs can be a significant expense during retirement. Consider long-term care insurance if necessary.</p>
                              </div> 
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Debt Management</h4>
                                 <p>Work towards paying off high-interest debt before retirement to reduce financial stress.</p>
                              </div> 
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Emergency Fund</h4>
                                    <p>Maintain an emergency fund to cover unexpected expenses, helping to avoid tapping into retirement savings prematurely.</p>
                              </div> 
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Review and Adjust</h4>
                                    <p> Periodically review your retirement plan to account for changes in your financial situation, goals, or market conditions. Adjust your strategy as needed.</p>
                              </div> 
                           </div>
                         
                        <div class="tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              {/* <h3 class="tp-service-details-subtitle-2">Social Security</h3> */}
                              <p>Retirement planning is crucial because it allows individuals to take control of their financial future, maintain their desired lifestyle, and ensure they have sufficient funds to cover living expenses during retirement. An individual works from 29 to 65 (36 years on average) the earlier one starts planning for retirement, the more time they have to benefit from compounding returns on investments.</p>
                           </div> 
                        </div> 
                        <div class="tp-service-bg tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              <h3 class="tp-service-details-subtitle-2">We offer free consultation</h3>
                              <p>At Kubera Financial Services, we offer solutions to harness the power of compounding and achieve an optimized retirement with tax-free benefits
</p>
                              <h6>Please reach us for free consultation!</h6>
                              <a class="tp-btn-service" href="/ContactUs">Contact Us</a>
                           </div> 
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
    </main>
    <Footer/>

  </> 
  ); 
  } 
  export default RetirementPlanning;