import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function LifeInsurance() { 
	return( 
	<>  
    
   <Header />  
    <main> 
    <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section class="tp-service-details-area pt-120 pb-120">
            <div class="container">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="tp-service-widget">
                        <div class="tp-service-widget-item mb-40">
                           <div class="tp-service-widget-tab">
                              <h3 class="tp-service-widget-title">Our service</h3>
                              <ul>
                                 <li><a href="/Services">Estate Planning</a></li>
                                 <li><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                 <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                 <li class="active"><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                 <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                 <li><a href="/DebtManagement">Debt Management</a></li> 
                                 <li><a href="/WealthManagement">Wealth Management</a></li> 
                                 <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                 <li><a href="/NotaryPerson">Notary in person</a></li> 
                                 <li><a href="/NotaryRemote">Notary remote online</a></li> 
                                 <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                 {/* <li><a href="/Mortgageloan">Mortgage loan Officer</a></li>  */}
                              </ul>
                           </div>
                        </div> 
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Life Insurance & Annuities</h3>
                        <div class="tp-postbox-list mr-40">
                           <h2 class="tp-postbox-list-title-1">Purpose:</h2>
                           <h4 class="tp-postbox-list-title"><i class="fa-regular fa-check"></i>Protection</h4>
                           <p>Life insurance is primarily designed to provide a financial benefit to beneficiaries in the event of the policyholder's death. This benefit, known as the death benefit, is paid out to the policy's beneficiaries, offering financial security and support.</p>
                        </div>
                       <div class="tp-service-details-thumb">
                           <img src="../assets/images/s1.jpeg" alt=""/> 
                        </div> 
                        <div class="tp-postbox-list-wrapper">

                              <div class="tp-postbox-list mr-40">
                                 <h2 class="tp-postbox-list-title-1">Types of Life Insurance:</h2>                          
                                 <h4 class="tp-postbox-list-title"><i class="fa-regular fa-check"></i> Term Life Insurance</h4>
                                 <p>Provides coverage for a specific term (e.g., 10, 20, or 30 years). If the policyholder dies during the term, the death benefit is paid to the beneficiaries. Term life insurance does not have a cash value component.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Whole Life Insurance</h4>
                                    <p>Offers coverage for the entire life of the insured. It has a cash value component that grows over time and can be accessed by the policyholder. Whole life insurance premiums are typically higher than term life premiums.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i> Universal Life Insurance</h4>
                                    <p>Combines a death benefit with a cash value component and provides flexibility in premium payments and death benefits.</p>
                              </div> 
                           </div>
                         
                        <div class="tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              <h3 class="tp-service-details-subtitle-2">Features:</h3>                              
                              <h4 class="tp-service-details-subtitle-3">Cash Value</h4>
                              <p>Whole life and universal life insurance policies have a cash value component that accumulates over time. This cash value can be borrowed against or withdrawn by the policyholder under certain conditions.</p>
                              <h4 class="tp-service-details-subtitle-3">Premiums</h4>
                              <p>Premiums for life insurance policies are typically paid on a regular basis (e.g., monthly or annually) and are determined based on factors such as the insured's age, health, and the type of policy.</p>
                              <h4 class="tp-service-details-subtitle-3">Death Benefit</h4>
                              <p>The death benefit is the amount paid to beneficiaries upon the death of the insured. It is generally tax-free.</p>
                           </div> 
                        </div>
 
                     </div>
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Annuities</h3>
                        <div class="tp-postbox-list mr-40">
                           <h2 class="tp-postbox-list-title-1">Purpose:</h2>
                           <h4 class="tp-postbox-list-title"><i class="fa-regular fa-check"></i>Income Stream</h4>
                           <p>Annuities are financial products designed to provide a stream of income, often during retirement. They can be used to ensure a steady income flow for a specified period or for the rest of the annuitant's life.</p>
                        </div>
                       <div class="tp-service-details-thumb">
                           <img src="../assets/images/s1.jpeg" alt=""/> 
                        </div> 
                        <div class="tp-postbox-list-wrapper">

                              <div class="tp-postbox-list mr-40">
                                 <h2 class="tp-postbox-list-title-1">Types of Annuities:</h2>                          
                                 <h4 class="tp-postbox-list-title"><i class="fa-regular fa-check"></i> Immediate Annuities</h4>
                                 <p>Begin providing income immediately after a lump sum is paid to the insurance company. The income stream can be fixed or variable.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Deferred Annuities</h4>
                                    <p>Allow the annuitant to accumulate funds over a period before converting the account into an income stream. Deferred annuities can be fixed, variable, or indexed.</p>
                              </div> 
                           </div>
                         
                        <div class="tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              <h3 class="tp-service-details-subtitle-2">Features:</h3>                              
                              <h4 class="tp-service-details-subtitle-3">Accumulation Phase</h4>
                              <p>During the accumulation phase, the annuity grows in value based on contributions and investment returns. This phase continues until the annuitant decides to start receiving income.</p>
                              <h4 class="tp-service-details-subtitle-3">Distribution Phase</h4>
                              <p>The annuitant can choose to receive regular payments during the distribution phase. The payments can be fixed, variable, or indexed based on the type of annuity.</p>
                              <h4 class="tp-service-details-subtitle-3">Tax Deferral</h4>
                              <p>Earnings within an annuity grow on a tax-deferred basis, meaning taxes are not paid until withdrawals or income payments are made.</p>
                              <h4 class="tp-service-details-subtitle-3">Death Benefit (Variable Annuities)</h4>
                              <p>Some variable annuities offer a death benefit, ensuring that beneficiaries receive a specified amount even if the annuitant dies before annuitizing the contract.</p>
                           </div> 
                        </div>
                        <div class="tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30"> 
                              <p>It's important to carefully consider personal financial goals, risk tolerance, and individual circumstances when deciding on life insurance or annuities. Consulting with a financial advisor is recommended to ensure that these financial products align with specific needs and objectives.</p>
                              </div> 
                        </div>
                        <div class="tp-service-bg tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              <h3 class="tp-service-details-subtitle-2">We offer free consultation</h3>
                              <p>At Kubera Financial Services, we are proud to have licensed health insurance agents covering all states. We provide our clients with access to top-quality offerings from the nation's top insurance providers, ensuring value and comprehensive coverage.</p>
                           
                              <h6>Please reach us for free consultation!</h6>
                              <a class="tp-btn-service" href="/ContactUs">Contact Us</a>
                           </div> 
                        </div>
 
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
    </main>
    <Footer/>

  </> 
  ); 
  } 
  export default LifeInsurance;