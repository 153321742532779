import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
import GreatValues from '../Home/GreatValues';  
function AboutUs() { 
	return( 
	<>  
    
   <Header />  

    <main>
        <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="tp-about-area p-relative pt-130 pb-210"> 
            <div class="container">
               <div class="row">
                  <div class="col-lg-6">
                     <div class="tp-about-thumb-wrapper p-relative wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".3s">
                        <div class="main">
                           <img src="../assets/images/about-1.png" alt=""/>
                        </div>
                        <img class="shape-1" src="../assets/images/img-2.jpg" alt=""/>
                        <img class="shape-2" src="../assets/images/shape-1.png" alt=""/>
                        <img class="shape-3" src="../assets/images/shape-2.png" alt=""/> 
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="tp-about-wrapper">
                        <div class="tp-about-title-wrapper">
                           <span class="tp-section-title-pre">ABOUT US</span>
                           <h3 class="tp-section-title">Kubera Financial Services LLC </h3>
                        </div>
                        <p>Kubera Financial Services LLC primary mission is to provide financial freedom for our clients .Our vision is to be a step ahead in the financial revolution.We offer comprehensive financial services designed to help you achieve financial security and peace of mind. </p>
                        <p>We specialize in Investment Management, Retirement Planning, and Tax Services. Our team of experienced professionals is dedicated to understanding your financial goals and providing you with the personalized strategies necessary to reach them.</p>
                        <p>We believe in building long-term relationships with our clients, providing the guidance and support you need to navigate the complex world of finance. Trust us to help you.</p>
                        {/* <div class="tp-about-wrapper-list">
                           <ul>
                              <li><span><i class="fa-regular fa-circle"></i></span> Notary in person (IPEN)</li>
                              <li><span><i class="fa-regular fa-circle"></i></span> Notary remote online (RON)</li>
                              <li><span><i class="fa-regular fa-circle"></i></span> Notary for loan processing (NSA)</li>
                              <li><span><i class="fa-regular fa-circle"></i></span> Mortgage loan Officer (MLO)</li>
                           </ul>
                        </div> */}
                        <div class="tp-about-btn">
                           <a class="tp-btn" href="/ContactUs">Request a Quote</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>


        {/* <section class="tp-faq-area p-relative pt-120 pb-120" data-bg-color="#20536D">
            <div class="bg-patten"><img src="../assets/images/patten-bg.png" alt="" /></div>
            <div class="container">
               <div class="row">
                  <div class="col-lg-5">
                     <div class="tp-faq-wrapper">
                        <div class="tp-faq-title-wrapper">
                           <span class="tp-section-title-pre">ask anything</span>
                           <h3 class="tp-section-title">You can contact <br/> us for any question </h3>
                           <p>Fact that a reader will be distrol acted bioiiy desig the.ished fact that a reader will be distrol acted bioiiy bioiiy desig the.ished fact that a reader ished fact that </p>
                        </div>
                        <div class="tp-faq-counter-wrapper d-flex">
                           <div class="tp-faq-counter d-flex align-items-center mr-20 mb-30">
                              <div class="tp-faq-counter-icon">
                                 <span>
                                    <img src="assets/img/faq/icon-1.svg" alt=""/>
                                 </span>
                              </div>
                              <div class="tp-faq-counter-content">
                                 <h4 class="tp-faq-counter-title">20+</h4>
                                 <p>Experiences</p>
                              </div>
                           </div>
                           <div class="tp-faq-counter d-flex align-items-center mb-30">
                              <div class="tp-faq-counter-icon">
                                 <span>
                                    <img src="assets/img/faq/icon-2.svg" alt=""/>
                                 </span>
                              </div>
                              <div class="tp-faq-counter-content">
                                 <h4 class="tp-faq-counter-title">10+</h4>
                                 <p>Happy Client</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-7">
                     <div class="tp-faq-tab-content tp-accordion wow fadeInRight" data-wow-duration="1s" data-wow-delay=".3s">
                        <div class="accordion" id="general_accordion">
                           <div class="accordion-item tp-faq-active">
                              <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                 What is business consulting?
                                </button>
                              </h2>
                              <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#general_accordion">
                                <div class="accordion-body">
                                  <p>Business consulting is a service provided by professionals who offer expert advice and guidance to organizations seeking to improve their business processes, strategies, and operations</p>
                                </div>
                              </div>
                            </div>
                           <div class="accordion-item">
                             <h2 class="accordion-header" id="headingTwo">
                               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                 What types of businesses typically use consulting services?
                               </button>
                             </h2>
                             <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#general_accordion">
                               <div class="accordion-body">
                                 <p>Business consulting is a service provided by professionals who offer expert advice and guidance to organizations seeking to improve their business processes, strategies, and operations</p>
                               </div>
                             </div>
                           </div>
                           <div class="accordion-item">
                             <h2 class="accordion-header" id="headingThree">
                               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                 What are the benefits of using a business consulting service?
                               </button>
                             </h2>
                             <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#general_accordion">
                               <div class="accordion-body">
                                 <p>Business consulting is a service provided by professionals who offer expert advice and guidance to organizations seeking to improve their business processes, strategies, and operations</p>
                               </div>
                             </div>
                           </div>
                           <div class="accordion-item">
                             <h2 class="accordion-header" id="headingFour">
                               <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                 How much do consulting services cost?
                               </button>
                             </h2>
                             <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#general_accordion">
                               <div class="accordion-body">
                                 <p>Business consulting is a service provided by professionals who offer expert advice and guidance to organizations seeking to improve their business processes, strategies, and operations</p>
                               </div>
                             </div>
                           </div>
                         </div>
                     </div>
                  </div>
               </div>
            </div>
        </section>

        <GreatValues/>  */}


         
    </main>
   <Footer/>

  </> 
  ); 
  } 
  export default AboutUs;