
function Specialize() { 
	return( 
	<>
    
    <section class="tp-service-area-3 pt-120 pb-90" data-bg-color="#fff">
            <div class="container">
               <div class="row">
                  <div class="col-lg-4 col-md-8">
                     <div class="tp-service-title-wrapper"> 
                        <h3 class="tp-section-title">We Specialize,</h3>
                     </div>
                  </div>
                  <div class="tp-service-area col-lg-6 col-md-4"> 
                     <div class="tp-service-active swiper-container wow fadeInUp" data-wow-duration="1s"  data-wow-delay=".3s">
                        <div class="swiper-wrapper">
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>We believe in building long-term relationships with our clients, providing the guidance and support you need to navigate the complex world of finance. Trust us to help you master your financial future.</p>
                                 </div> 
                           </div>
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>Don't just take our word for it hear what our customers have to say about us! we have helped thousand people</p>
                                 </div> 
                           </div>
                           <div class="swiper-slide">
                                 <div class="tp-service-item-content"> 
                                    <p>Don't just take our word for it hear what our customers have to say about us! we have helped thousand people</p>
                                 </div> 
                           </div> 
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-2 col-md-4">
                     <div class="tp-service-nav text-end">
                        <button type="button" class="service-button-prev-1"><i class="fa-regular fa-arrow-left"></i>
                        </button>
                        <button type="button" class="service-button-next-1"><i class="fa-regular fa-arrow-right"></i>
                        </button>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-lg-4 col-md-6">
                     <div class="tp-service-item-wrapper-3 p-relative mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
                        <div class="tp-service-item-content-3">
                           <div class="tp-service-item-icon-3">
                              <img src="./assets/images/icon-1.png" alt="" />
                           </div>
                           <h4 class="tp-service-item-title-3">Investment</h4>
                           <span>Management</span>
                           <p>Our mission is there are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration </p>
                           <div class="tp-service-item-number"> 
                              <img src="./assets/images/gold-right-arrow.png" alt="" />
                           </div>
                        </div> 
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="tp-service-item-wrapper-3 p-relative mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".5s">
                        <div class="tp-service-item-content-3">
                           <div class="tp-service-item-icon-3">
                              <img src="./assets/images/icon-2.png" alt="" />
                           </div>  
                           <h4 class="tp-service-item-title-3">Retirement </h4>
                           <span>Planning </span>
                           <p>Our mission is there are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration </p>
                           <div class="tp-service-item-number"> 
                              <img src="./assets/images/gold-right-arrow.png" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="tp-service-item-wrapper-3 p-relative mb-30 wow fadeInUp" data-wow-duration="1s" data-wow-delay=".7s">
                        <div class="tp-service-item-content-3">
                           <div class="tp-service-item-icon-3">
                              <img src="./assets/images/icon-3.png" alt="" />
                           </div>
                           <h4 class="tp-service-item-title-3">Tax</h4>
                           <span>Services</span>
                           <p>Our mission is there are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration </p>
                           <div class="tp-service-item-number"> 
                              <img src="./assets/images/gold-right-arrow.png" alt="" />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
  </> 
  ); 
  } 
  export default Specialize;