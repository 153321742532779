import React from 'react';

function  Header () {
  return (
  <>
  
     
  <header class="tp-header-area-3 p-relative">
         <div class="tp-header-box-3"> 
            <div class="tp-header-box-main-3">
               <div class="tp-header-top-3 d-none d-xl-block">
                  <div class="container">
                     <div class="row align-items-center">
                        <div class="col-xxl-12 col-xl-12">
                            <div class="tp-header-top-info-3 tp-header-top-right-3 d-flex justify-content-end align-items-center">
                                <ul>
                                    <li>
                                        <a href="#"><span><i class="fa-solid fa-envelope"></i></span>getrich@kuberafinancialservices.com</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank"> <span><i class="fa-solid fa-phone"></i></span>813 809 6438</a>
                                    </li> 
                                </ul>
                           </div>
                        </div>
                        
                     </div>
                  </div>
               </div>
   
            <div class="container"> 
      <div id="header-sticky" class="tp-header-main-sticky p-relative">
                     <div class="row align-items-center"> 
                        <div class="col-xxl-1 col-xl-1 col-3">
                           <div class="logo-home-3 home-3 d-flex align-items-center p-absolute">
                              <div class="tp-main-menu-logo d-block">
                                 <a href="/">
                                    <img src="assets/images/logo.jpeg" alt="" />
                                 </a>
                              </div>
                           </div> 
                           </div>
                        <div class="col-xxl-5 col-xl-5 col-6">
                           <div class="logo-text">
                              <h1>Kubera Financial Services LLC</h1>
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-1"> 
                           <div class=" tp-main-menu home-3 d-flex align-items-center justify-content-xl-end">
                              <div class="d-none d-xl-flex">
                                 <nav class="tp-main-menu-content">
                                    <ul>
                                       <li><a href="/">Home</a></li>
                                       <li><a href="/AboutUs">About Us</a></li>  
                                       <li class="has-dropdown">
                                          <a href="/Services">Services</a>
                                          <ul class="submenu">
                                             <li><a href="/Services">Estate Planning</a></li>
                                             <li><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                             <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                             <li><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                             <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                             <li><a href="/DebtManagement">Debt Management</a></li>
                                             <li><a href="/WealthManagement">Wealth Management</a></li> 
                                             <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                             <li><a href="/NotaryPerson">Notary in person</a></li> 
                                             <li><a href="/NotaryRemote">Notary remote online</a></li> 
                                             <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                           </ul>
                                       </li>
                                       <li><a href="/Payment">Payment</a></li> 
                                       <li><a href="/ContactUs">Contact Us</a></li>
                                       
                                    </ul>
                                 </nav>
                              </div> 
                           </div>
                        </div> 
                        <div class="col-xl-2 col-1 md-none">
                        <div class="tp-header-main-right-2 d-flex align-items-center justify-content-xl-end">
                           {/* <div class="tp-header-main-right-hamburger-btn d-xl-none offcanvas-open-btn text-end">
                              <button class="hamburger-btn">
                                 <span></span>
                                 <span></span>
                                 <span></span>
                              </button>
                           </div> */}
                           <input type="checkbox" id="check"/>
                           <label for="check">
                              <i class="fas fa-bars" id="btn"></i> 
                           </label> 
                           <div class="sidebar">
                           <div class="sidebar-content">
                              <div class="side-bar-top"> 
                              
                              <label for="check"> 
                                 <i class="fas fa-times" id="cancel"></i>
                              </label>
                              
                              <div class="sidebar-logo">
                                 <a href="/">
                                    <img src="assets/images/logo.jpeg" alt="" />
                                 </a>
                              </div>
                              </div>
                              <ul>
                                       <li><a href="/">Home</a></li>
                                       <li><a href="/AboutUs">About Us</a></li>  
                                       <li>  <a href="/Services">Services</a></li>
                                       
                                       <li><a href="/Payment">Payment</a></li> 
                                       <li><a href="/ContactUs">Contact Us</a></li>
                                       
                                    </ul>

                                    <div class="offcanvas__contact">
               <h4 class="offcanvas__title">Kubera Financial Services</h4> 
               <div class="offcanvas__contact-content d-flex">
                  <div class="offcanvas__contact-content-icon">
                     <i class="fa-solid fa-envelope"></i>
                  </div>
                  <div class="offcanvas__contact-content-content">
                     <a href="#"> <span class="__cf_email__" data-cfemail="">getrich@kuberafinancialservices.com</span>  </a>
                  </div>
               </div>
               <div class="offcanvas__contact-content d-flex">
                  <div class="offcanvas__contact-content-icon">
                     <i class="fa-solid fa-phone"></i>
                  </div>
                  <div class="offcanvas__contact-content-content">
                     <a href="#"> 813 809 6438</a>
                  </div>
               </div>
            </div>
            <div class="offcanvas__social">
               <a class="icon facebook" target="_blank"href="https://www.facebook.com/people/Kubera-Financial-Services-LLC/61558366419478/"><i class="fab fa-facebook-f"></i></a>
               <a class="icon twitter"target="_blank" href="https://twitter.com/kfsllc"><i class="fab fa-twitter"></i></a>
 
 
                                 
            </div>
                           </div>
                           </div>
                        </div>
                     </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </header> 
       
  </>
);
}

export default Header;