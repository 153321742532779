import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
function Services() { 
	return( 
	<>  
    
   <Header />  
    <main> 
    <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                  
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    <section class="tp-service-details-area pt-120 pb-120">
            <div class="container">
               <div class="row">
                  <div class="col-lg-4">
                     <div class="tp-service-widget">
                        <div class="tp-service-widget-item mb-40">
                           <div class="tp-service-widget-tab">
                              <h3 class="tp-service-widget-title">Our service</h3>
                              <ul>
                                 <li class="active"><a href="/Services">Estate Planning</a></li>
                                 <li><a href="/RetirementPlanning">Retirement Planning</a></li> 
                                 <li><a href="/TaxOptimization">Tax Optimization</a></li> 
                                 <li><a href="/LifeInsurance">Life Insurance & Annuities</a></li> 
                                 <li><a href="/ExpertRollovers">Expert 401K Rollovers</a></li> 
                                 <li><a href="/DebtManagement">Debt Management</a></li> 
                                 <li><a href="/WealthManagement">Wealth Management</a></li> 
                                 <li><a href="/BusinessOpportunity">Business Opportunity</a></li> 
                                 <li><a href="/NotaryPerson">Notary in person</a></li> 
                                 <li><a href="/NotaryRemote">Notary remote online</a></li> 
                                 <li><a href="/NotaryLoan">Notary for loan processing</a></li> 
                                 {/* <li><a href="/Mortgageloan">Mortgage loan Officer</a></li>  */}
                              </ul>
                           </div>
                        </div> 
                     </div>
                  </div>
                  <div class="col-lg-8">
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Estate Planning</h3>
                        <p>A comprehensive and up-to-date Estate Plan is critical to have in place to protect you and your family. Here are some of the benefits: </p>
                        <p>With a Medical Power of Attorney and Living Will – Advanced Directive, you can decide who will make healthcare and end-of-life decisions for you, if you are unable to do so for yourself.  Without this document, your loved ones will have to petition a court to be appointment to make these critical decisions.</p>
                        <div class="tp-service-details-thumb">
                           <img src="../assets/images/s1.jpeg" alt=""/> 
                        </div> 
                        <div class="tp-postbox-list-wrapper">
                              <div class="tp-postbox-list mr-40">
                                 <h4 class="tp-postbox-list-title"><i class="fa-regular fa-check"></i> You decide who gets your assets</h4>
                                 <p>Without a Will state law dictates who gets your assets.  This is true even if you have already told friends and family which assets you want them to have when you die.  Regardless of how much or how little you may have, make sure your wishes are known by creating a Will.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>Takes care of your children </h4>
                                    <p>Who will take care of your minor children if something were to happen to you?  </p>
                                 <p>Without naming a Guardian in your Will, a judge will make the decision as to who will have the care, custody and control of your children and the inheritance they receive.  </p>
                                 <p>Often times this can cause family turmoil, as different family members fight for custody of the child.  The only way to let your wishes be known is by naming a Guardian in your Will.  </p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i> You Can Avoid Probate</h4>
                                 <p>Ask anyone who has dealt with the death of a family member who didn’t have a Will or Trust in place, and they’ll tell you how frustrating, time consuming, and expensive it is to get everything sorted out.</p>
                                 <p>By creating a Revocable Living Trust and transferring your assets to it, you can save your family the time and expense of the probate process.</p>
                              </div>
                              <div class="tp-postbox-list">
                                 <h4 class="tp-postbox-list-title">
                                    <i class="fa-regular fa-check"></i>You Can Control Medical Decisions</h4>
                                 <p>With a Medical Power of Attorney and Living Will – Advanced Directive, you can decide who will make healthcare and end-of-life decisions for you, if you are unable to do so for yourself.  </p>
                                 <p>Without this document, your loved ones will have to petition a court to be appointment to make these critical decisions.</p>
                              </div> 
                           </div>
                         
                        <div class="tp-service-bg tp-service-details-box-2 tp-service-details-box-4 d-flex mb-40">
                           <div class="tp-service-details-box-content mr-30">
                              <h3 class="tp-service-details-subtitle-2">We offer free consultation</h3>
                              <p>At Kubera Financial Services LLC we offer free consultation for will and trust. We guide you how to file will and trust online.Every life changing event needs an amendment in your will and trust</p>
                              <p>Change in marital status,Buying or selling real estate, new born child, adopting a child,relocation to a different state,change in employment,child turning 18,selling a business, buying a business,retirement , home assisted living care, losing of loved ones</p>
                              <h6>Please reach us for free consultation!</h6>
                              <a class="tp-btn-service" href="/ContactUs">Contact Us</a>
                           </div> 
                        </div>
 
                     </div>
                  </div>
               </div>
            </div>
         </section>
        
    </main>
    <Footer/>

  </> 
  ); 
  } 
  export default Services;