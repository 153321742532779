
function RequestQuote() { 
	return( 
	<>
    
    <section class="tp-service-area-3 pt-60 pb-90" data-bg-color="#fff">
         <div class="container"> 
            <div class="row">   
               <div class="tp-service-details-box-2 d-flex mb-40">
                  <div class="tp-service-details-box-content">
                     <h3 class="tp-service-details-subtitle-2">Request an Financial Services </h3>
                     <p>Licenced notary available 24x7 with one phone call away . We come to your doorstep or meet at common place.</p>
                     <div class="tp-hero-btn mr-30"><a class="tp-btn" href="#">Request a Quote</a></div>
                  </div>
                  <div class="tp-service-details-box-thumb">
                     <img src="../assets/images/request.png" alt=""/>
                  </div>
               </div>
            </div>
         </div>
      </section>
        
  </> 
  ); 
  } 
  export default RequestQuote;