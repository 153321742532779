import Header from '../../components/Header';  
import Footer from '../../components/Footer'; 
import GreatValues from '../Home/GreatValues';  
function Payment() { 
	return( 
	<>  
    
   <Header />  

    <main>
        <section class="tp-hero-area p-relative">
            <div class="tp-hero-wrapper-slider">
               <div class="swiper-container">
                 
               <div class="swiper-wrapper">
                        <div class="swiper-slide pt-20 pb-150 tp-hero-bg-image" data-bg-color="#20536d">
                            <img class="tp-hero-bg" src="../assets/images/banner-bg.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="tp-about-area p-relative pt-130 pb-110"> 
            <div class="container">
               <div class="row">  
                  {/* <div class="col-xl-3 col-lg-4 col-md-3">
                    <div class="blog-section-two">
                      <div class="single-blog h-calc">
                          <div class="blog-img position-relative">
                           <a href="blog-details.html"> 
                             <img src="assets/images/bank.jpg" class="img-fluid w-100" alt="img"/>
                           </a>
                            <div class="blog-img-overlay-text"></div>
                          </div> 
                          <div class="paymentDetails card-style box-shadow border-0 mb-24">
                            <div class="blog-info">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="blog-info-title">
                                  <h4 class="title text-capitalize">Bank account</h4>
                                  <p class="subtitle">Account number: </p>
                                  <div class="priceListing">
                                      <ul class="listing">
                                          <li class="listItem"><p class="leftCap font-600">898149542673 </p> </li>
                                     </ul>
                                  </div>
                                  <p class="subtitle">Routing number: </p>
                                  <div class="priceListing">
                                      <ul class="listing">
                                      <li class="listItem"><p class="leftCap font-600">063100277 (paper & electronic)</p> </li>
                                       <li class="listItem"><p class="leftCap font-600">026009593 (wires)</p> </li>
                                     </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>  */}
                  <div class="col-lg-12">
                     <div class="tp-service-details-wrapper"> 
                        <h3 class="tp-service-details-title">Payment</h3> 
                     </div>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-3">
                    <div class="blog-section-two">
                      <div class="single-blog h-calc">
                          <div class="blog-img position-relative">
                           <a> 
                             <img src="assets/images/paypal.jpg" class="img-fluid" alt="img"/>
                           </a> 
                          </div> 
                          <div class="paymentDetails card-style box-shadow border-0 mb-24">
                            <div class="blog-info">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="blog-info-title">
                                  <h4 class="title text-capitalize">Paypal</h4> 
                                  
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div> 
                  <div class="col-xl-3 col-lg-4 col-md-3">
                    <div class="blog-section-two blog-section-two1">
                      <div class="single-blog h-calc">
                          <div class="blog-img position-relative">
                           <a> 
                             <img src="assets/images/qrcode.svg" class="img-fluid" alt="img"/>
                           </a> 
                          </div> 
                          <div class="paymentDetails card-style box-shadow border-0 mb-24">
                            <div class="blog-info">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="blog-info-title">
                                  <h4 class="title text-capitalize">Venmo</h4> 
                                  
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div> 

                  <div class="col-xl-3 col-lg-4 col-md-3">
                    <div class="blog-section-two blog-section-two1">
                      <div class="single-blog h-calc">
                          <div class="blog-img position-relative">
                           <a> 
                             <img src="assets/images/cashapp.png" class="img-fluid" alt="img"/>
                           </a> 
                          </div> 
                          <div class="paymentDetails card-style box-shadow border-0 mb-24">
                            <div class="blog-info">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="blog-info-title">
                                  <h4 class="title text-capitalize">Cash App</h4> 
                                  
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div> 

                  <div class="col-xl-3 col-lg-4 col-md-3">
                    <div class="blog-section-two blog-section-two1">
                      <div class="single-blog h-calc">
                          <div class="blog-img position-relative">
                           <a> 
                             <img src="assets/images/Zelle.png" class="img-fluid" alt="img"/>
                           </a> 
                          </div> 
                          <div class="paymentDetails card-style box-shadow border-0 mb-24">
                            <div class="blog-info">
                              <div class="d-flex justify-content-between align-items-center">
                                <div class="blog-info-title">
                                  <h4 class="title text-capitalize">Zellepay</h4> 
                                  
                                </div>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div> 

               </div>
            </div>
                    
         </section>


   
    </main>
   <Footer/>

  </> 
  ); 
  } 
  export default Payment;